import { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom"
import styles from "./PageBody.module.css"
import { ReactComponent as TwitterLogo } from "./twitter-circle.svg"
import { ReactComponent as InstagramLogo } from "./insta.svg"


function useWindowSize() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export function PageBody() {
    const width = useWindowSize();

    return (
        <div className={styles.root}>
            { width > 1000 ? 
            <div className={styles.imgLeft} >
                <div style={{ height: "80px"}}></div>
                <img src={"/ink6.png"} alt={"bathink"} className={styles.img}/>
            </div>
            : <div className={styles.leftSpace}/> 
            }
            <div className={styles.centreColumn}>
                <div style={{ height: "50px"}}></div>
                {width > 1000 ?
                    <div className={styles.bannerParent}>
                        <img src={"/circle_pic.jpg"} alt={"banner"} className={styles.banner}/>
                    </div>
                    :
                    <div className={styles.bannerParent}>
                        <img src={"/circle_pic.jpg"} alt={"banner"}
                        className={styles.banner} style={{"width": "100px"}}/>
                    </div>
                }
                <div style={{ height: "50px"}}></div>
                <div className={styles.subtitle}>about</div>
                <div style={{ height: "10px" }}></div>
                <div className={styles.textContent}>Published in February 2023, Bath Ink is a literary anthology featuring the work of graduate writers from Bath Spa University's Creative Writing MA. This collection showcases vibrant new writing and serves as an open resource for editors and interested readers.</div>
                <div style={{ height: "60px"}}></div>
                <div className={styles.subtitle}>contact</div>
                <div style={{ height: "10px" }}></div>
                <div className={styles.contactRow}>
                    <div className={styles.textContent}>bsuanthology2021@gmail.com</div>
                    <Link to={"/twitter"} className={styles.socialLink}>
                        <TwitterLogo className={styles.twitterLogo} />
                    </Link>
                    <Link to={"/instagram"} className={styles.socialLink}>
                        <InstagramLogo className={styles.instagramLogo} />
                    </Link>
                </div>
                <div style={{ height: "80px"}}></div>
                <a href={"/BathInk Anthology.pdf"} className={styles.downloadButton}>
                    <div className={styles.downloadButtonSpacer} />
                    <span>anthology</span>
                    <div className={styles.downloadButtonSpacer} />
                </a>
                <div style={{ height: "80px" }}></div>
                <a href={"/In Memory of Jennie Beck.pdf"} className={styles.jennieButton}>
                    <div className={styles.downloadButtonSpacer} />
                    <span>tribute to Jennie Beck</span>
                    <div className={styles.downloadButtonSpacer} />
                </a>
                <div style={{ height: "80px" }}></div>
            </div>

            {width > 1000 ?
                <div className={styles.imgRight} >
                    <div style={{ height: "80px"}}></div>
                    <img src={"/ink4.png"} alt={"bathink"} className={styles.img} />
                </div>
                : <div className={styles.rightSpace} />
            }
        </div>
    )
}