import styles from "./LandingPage.module.css"
import {PageBody} from "./PageBody";

export function LandingPage() {
  return (
    <div className={styles.root}>
        <PageBody />
    </div>
  );
}
