import styles from "./Privacy.module.css"



export function Privacy() {
    return (
        <div className={styles.root}>
            <div className={styles.spacer}/>
            <span>TODO: privacy statement</span>
            <div className={styles.spacer}/>
        </div>
    )
}