import styles from "./AnthologyContent.module.css"

export function AnthologyContent() {
    return (
        <div className={styles.root}>
            <div className={styles.spacer}/>
            <span>Coming soon...</span>
            <div className={styles.spacer}/>
        </div>
    )
}