import './App.css';
import { LandingPage } from "./features/landingPage/LandingPage";

// export function isMobile() {
//     return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// }

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LandingPage />
      </header>
    </div>
  );
}

export default App;
