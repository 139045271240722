import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import {
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";
import {Privacy} from "./features/privacy/Privacy";
import {AnthologyContent} from "./features/anthologyContent/AnthologyContent";


export function Twitter() {
    window.location.href = 'https://twitter.com/BathInk_2021';
    return null;
}

export function Instagram() {
    window.location.href = 'https://www.instagram.com/bathink_2021/';
    return null;
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/privacy",
        element: <Privacy />,
    },
    {
        path: "/poems",
        element: <AnthologyContent />,
    },
    {
        path: "/stories",
        element: <AnthologyContent />,
    },
    {
        path: "/twitter",
        element: <Twitter />,
    },
    {
        path: "/instagram",
        element: <Instagram />,
    }
]);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
